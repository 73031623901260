// Vendors
import { push } from 'connected-react-router';
//helpers
import { to } from '../../../lib/helpers';

// Requests
import { lookupAppLinkRequest } from '../../../backend/requests';

import { APP_TRANSACTION_IDS, clearAppData, storeBasicInfo } from '../../app/appActions';

export const TPWD_TPWDAS_CLEAR_DATA = '[TPWD] TPWDAS - Clear Data';
export const TPWD_MANAGE_ASSET = '[TPWD] Manage Asset';
export const TPWD_ADDITIONAL_ID_CARD_SELECTED = 'ADDITIONAL ID CARDS';
export const TPWD_ADDITIONAL_ID_CARD_QUANTITY = 'ADDITIONAL ID CARD QUANTITY';
export const TPWD_SELECTED_CATALOG_ITEMS = 'SELECTED CATALOG ITEMS';
export const TPWD_UPDATE_FEE_DETAILS = '[TPWD] UPDATE FEE DETAILS';
export const STEPS_CONFIG ='STEPS_CONFIG';
export const STEPS_CONFIG_DELETE = 'STEPS_CONFIG_DELETE';
export const STEPS_CONFIG_UPDATE = 'STEPS_CONFIG_UPDATE';
export const STEPS_CONFIG_ACTIVATE = 'STEPS_CONFIG_ACTIVATE';
export const catalogDescriptionMap = {
  RR01: "tpwd_renewal_RR01",
  RR02: "tpwd_renewal_RR02",
  RR03: "tpwd_renewal_RR03",
  RR04: "tpwd_renewal_RR04",
  RR05: "tpwd_renewal_RR05",
  RR06: "tpwd_renewal_RR06",
  RECRDB: "tpwd_RECRDB",
  RDECAL: "tpwd_RDECAL",
  REPCRD: "tpwd_REPCRD",
  RCARD: "tpwd_RCARD",
  SPDON: "tpwd_donation",
  HANDEF: "tpwd_processing_fee"
};

export const manageTPWDAsset = data => {
  return async function (dispatch) {
    await dispatch(storeBasicInfo(data));
    await dispatch(storeSelected(data));
    dispatch(push('/tpwdas'));
  };
};

export function storeSelected(data) {
  return {
    type: TPWD_MANAGE_ASSET,
    payload: data,
  };
}

export const clearTPWDASData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: TPWD_TPWDAS_CLEAR_DATA });
};

export function setAdditionalIdCardSelected(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_SELECTED,
    payload: data,
  };
}

export function storeAdditionalIdCardQuantity(data) {
  return {
    type: TPWD_ADDITIONAL_ID_CARD_QUANTITY,
    payload: data,
  };
}

export function setSelectedCatalogItemTypes(data) {
  return {
    type: TPWD_SELECTED_CATALOG_ITEMS,
    payload: data,
  };
}

export const updateFeeDetails = payload => {
  return {
    type: TPWD_UPDATE_FEE_DETAILS,
    payload,
  };
};

export const getTPWDFeeDetails = feeDetailsRequest => {
  return async function (dispatch, getStore) {
    const store = getStore();
    const { userKey, appId, selectedCatalogItemTypes, additionalIdCardQuantity } = store.tpwd.selected;

    const [{ body }, err] = await to(
      dispatch(
        lookupAppLinkRequest({
          transactionInfo: feeDetailsRequest,
          appId: appId,
          userKeys: [userKey],
          transactionId: APP_TRANSACTION_IDS.FEE_CALCULATION,
        })
      )
    );
    if (err) return Promise.reject(err);
    dispatch(updateFeeDetails({ ...body.data }));
    return Promise.resolve();
  };
};

export const formatTPWDPayment = (translate) => {
  return async function (dispatch, getStore) {
  
    const { tpwd, user } = getStore();
    const { profile: contDetails } = user;
    const { selectedCatalogItemTypes, additionalIdCardQuantity, updatedFeeDetails = {} } = tpwd;
    const { userKey, appId, agencyId, vin, link: { profile: { account } } } = tpwd.selected;
    const [accountDetails] = account;
    const { primaryOwnerAddress } = accountDetails;
    const userKeys = [userKey];
    const contactDetails = {
      firstName: contDetails.fName,
      middleName: '',
      lastName: contDetails.lName,
      suffix: '',
      entity: contDetails.businessName ? contDetails.businessName : '',
      customerType: contDetails.addressType,
      email: contDetails.userEmail,
      phone: contDetails.phoneNum,

      // imcNo: dmv.appProfile.imcNo ? dmv.appProfile.imcNo : '',
      mailingAddress: {
        address1: primaryOwnerAddress.address1,
        address2: primaryOwnerAddress.address2,
        city: primaryOwnerAddress.city,
        postalCode: primaryOwnerAddress.postalCode,
        stateProvince: primaryOwnerAddress.stateProvince,
      },
    };

    const assetDetails = {
      assetType: accountDetails.assetType,
      txNumber: accountDetails.txNumber,
      modelYear: accountDetails.modelYear,
      make: accountDetails.make,
      hullType: accountDetails.hullMaterial,
      length: accountDetails.length,
      expirationDate: accountDetails.expirationDate,
      additionalIdCardsQuantity: additionalIdCardQuantity,
      selectedCatalogItemTypes: selectedCatalogItemTypes.map((catalog) => catalog.code),
    };

    const feeDetails = [];
    updatedFeeDetails.paymentCatalogItems.forEach((fee) => {
      const feeDetail = {};
      feeDetail.feeCode = fee.code;
      if(fee.code === 'RECRDB')
        feeDetail.feeDescription = translate(catalogDescriptionMap['REPCRD']);
      else if(fee.code === 'RCARD')
        feeDetail.feeDescription = translate(catalogDescriptionMap['RCARD'], {1:additionalIdCardQuantity});
      else 
      feeDetail.feeDescription = translate(catalogDescriptionMap[fee.code]);
      feeDetail.feeAmount =  parseFloat(fee.unitPrice || 0).toFixed(2);
      if(fee.code === 'SPDON' && updatedFeeDetails.donationSelected)
      {
      feeDetails.push(feeDetail);}
      else if(fee.code !== 'SPDON') {
        feeDetails.push(feeDetail);}
    })
    // adding handling fee in feeDetails for email
    feeDetails.push({
      'code': 'HANDEF',
      'feeDescription': translate(catalogDescriptionMap['HANDEF']),
      'feeAmount': updatedFeeDetails.donationSelected ?
      updatedFeeDetails.convenienceFeeWithDonation
      : updatedFeeDetails.convenienceFeeWithOutDonation
    })
    const boatRenewalFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code !== 'SPDON').reduce((accumulator, currentValue) => {
      return accumulator + currentValue.unitPrice
    }, 0)
    const parksDonationFee = updatedFeeDetails.paymentCatalogItems.filter((fee) => fee.code === 'SPDON')[0].unitPrice;
    const transactionInfo = {
      ...assetDetails,
      contactDetails: contactDetails,
      transactionAmounts: {
        boatRenewalFee: boatRenewalFee,
        boatRenewalOption: true,
        feeDetails,
        subTotal: updatedFeeDetails.donationSelected ?
          updatedFeeDetails.totalFeeWithDonation : updatedFeeDetails.totalFeeWithOutDonation,
        handlingFeeOption: true,
        handlingFee: updatedFeeDetails.donationSelected ?
          updatedFeeDetails.convenienceFeeWithDonation
          : updatedFeeDetails.convenienceFeeWithOutDonation,
        parksDonationFee: updatedFeeDetails.donationSelected ? parksDonationFee : undefined,
        parksDonationOption: updatedFeeDetails.donationSelected ? true : undefined,
      }
    };

    return {
      userKeys,
      appId,
      agencyId,
      transactionId: APP_TRANSACTION_IDS.SUBMIT_CREATE_PAYMENT,
      transactionInfo,
    };
  };
};

export const setStepsConfig = payload => {
  return {
    type: STEPS_CONFIG,
    payload,
  };
};

export const setStepsConfigUpdate = (id) => {
  return {
    type: STEPS_CONFIG_UPDATE,
    id,
  };
};

export const setStepsConfigReload = (id) => {
  return {
    type: STEPS_CONFIG_ACTIVATE,
    id,
  };
};
export function getLookUpDataAndRenewTPWD({
  appId,
  userKeys,
  transactionId,
}) {
  return async function(dispatch) {
    const requestBody = {
      appId,
      userKeys,
      transactionId,
    };
    const [{ body }, err] = await to(
      dispatch(lookupAppLinkRequest(requestBody))
    );
    // dispatch(storeLookUp(body.data));
    dispatch(
      manageTPWDAsset({
        link: {profile: body.data.profiles[0]},
        userKey: body.data.profiles[0].userKey,
        appId,
        agencyId: '13',
      })
    );
    if (err) return Promise.reject(err);
    return Promise.resolve(body.data);
  };
}
